/* Updated App.css with Vercel-inspired dark theme */
:root {
  --bg-color: #000000;
  --text-color: #ffffff;
  --secondary-text: #888888;
  --border-color: #333333;
  --input-bg: #1a1a1a;
  --primary-color: #ffffff;
  --accent-color: #0070f3;
  --shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  --message-bg: #1a1a1a;
  --user-message-bg: #333333;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: var(--bg-color);
  border-bottom: 1px solid var(--border-color);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .App-header {
    padding: 0.75rem 1rem;
    flex-wrap: wrap;
  }
  
  .App-header h1 {
    font-size: 0.9rem;
  }
  
  .deploy-button {
    display: none; /* Hide on mobile */
  }
  
  .App-header nav ul {
    margin-top: 0.5rem;
  }
  
  .App-header nav li {
    margin-right: 1rem;
  }
}

.App-header h1 {
  margin: 0;
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 500;
}

.App-header nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.App-header nav li {
  margin-left: 1.5rem;
}

.App-header nav a {
  text-decoration: none;
  color: var(--secondary-text);
  font-weight: 500;
  padding: 0.5rem 0;
  transition: color 0.2s;
  font-size: 0.9rem;
}

.App-header nav a:hover {
  color: var(--text-color);
}

.model-selector {
  display: flex;
  align-items: center;
}

.model-selector select {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin-left: 0.5rem;
  font-size: 0.9rem;
}

.deploy-button {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.deploy-button svg {
  margin-right: 0.5rem;
}

.App-main {
  flex: 1;
  padding: 0;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.chat-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  position: relative;
}

.message-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden; /* Prevent nested scrollbars */
}

.messages {
  flex: 1;
  overflow-y: auto; /* Only this container should scroll */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--border-color) var(--bg-color);
  max-height: calc(100vh - 140px); /* Account for header and input */
}

/* Custom scrollbar styling */
.messages::-webkit-scrollbar {
  width: 6px;
}

.messages::-webkit-scrollbar-track {
  background: var(--bg-color);
}

.messages::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 3px;
}

@media (max-width: 768px) {
  .chat-container {
    width: 100%;
    margin: 0;
  }
  
  .messages {
    padding: 0.5rem;
  }
}

.welcome-logo {
  margin: 120px auto 30px;
  text-align: center;
}

.welcome-logo svg {
  height: 40px;
  width: 40px;
}

.welcome-message {
  text-align: center;
  max-width: 600px;
  padding: 0 1rem;
}

.welcome-message h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.welcome-message p {
  color: var(--secondary-text);
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

.welcome-message a {
  color: var(--accent-color);
  text-decoration: none;
}

.message {
  max-width: 800px;
  width: 100%;
  padding: 1.5rem 0;
  line-height: 1.6;
  border-bottom: 1px solid var(--border-color);
}

.user-message {
  background-color: transparent;
  color: var(--text-color);
}

.assistant-message {
  background-color: transparent;
  color: var(--text-color);
}

.source-citation {
  font-size: 0.8rem;
  color: var(--secondary-text);
  /* margin-top: 1rem;
  padding-top: 0.5rem; */
  /* border-top: 1px solid var(--border-color); */
}

.source-citation ul {
  margin: 0.5rem 0 0 0;
  padding-left: 1.5rem;
}

.source-citation li p {
  margin: 0;
}

.input-area {
  display: flex;
  padding: 1rem;
  background-color: var(--bg-color);
  border-top: 1px solid var(--border-color);
  margin-top: auto;
  position: sticky;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
}

.input-area input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  background-color: var(--input-bg);
  border-radius: 8px;
  font-size: 0.9rem;
  color: var(--text-color);
}

.input-area button {
  position: absolute;
  right: 1.75rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--secondary-text);
  cursor: pointer;
}

@media (max-width: 600px) {
  .input-area {
    padding: 0.75rem;
  }
  
  .input-area input {
    padding: 0.6rem;
    font-size: 0.85rem;
  }
  
  .input-area button {
    right: 1.25rem;
  }
}

/* Typing effect styling */
.typing-effect-container {
  position: relative;
  width: 100%;
  min-height: 20px; /* Ensure minimum height */
}

.typing-effect {
  width: 100%;
}

@keyframes cursor-blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  color: var(--secondary-text);
}

.dots {
  display: flex;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: var(--secondary-text);
  animation: dot-pulse 1.5s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-pulse {
  0%, 60%, 100% {
    transform: scale(1);
    opacity: 0.6;
  }
  20% {
    transform: scale(1.2);
    opacity: 1;
  }
}

/* Examples grid */
.examples-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  padding: 0 1rem;
}

@media (max-width: 600px) {
  .examples-container {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}

.example-card {
  background-color: var(--input-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.example-card:hover {
  background-color: #2a2a2a;
}

.example-card h4 {
  margin: 0;
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--text-color);
}

.example-card p {
  margin: 0.5rem 0 0;
  font-size: 0.8rem;
  color: var(--secondary-text);
}

/* Upload styling updates */
.upload-container {
  background-color: var(--input-bg);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  padding: 2rem;
  margin: 2rem;
  max-width: 800px;
  width: 100%;
}

.dropzone {
  border: 2px dashed var(--border-color);
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.dropzone:hover, .dropzone.active {
  border-color: var(--accent-color);
}

.file-list {
  margin-top: 1.5rem;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background-color: var(--input-bg);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 0.75rem;
}

.file-icon {
  margin-right: 0.75rem;
  color: var(--secondary-text);
}

.file-name {
  flex: 1;
  color: var(--text-color);
}

.file-status {
  margin-right: 0.75rem;
  color: var(--accent-color);
}

.button {
  display: inline-block;
  padding: 0.6rem 1rem;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  text-decoration: none;
  font-weight: normal;
  font-size: 0.9rem;
  transition: all 0.2s;
  cursor: pointer;
}

.button:hover {
  border-color: var(--text-color);
}

.button.primary {
  background-color: var(--text-color);
  color: var(--bg-color);
  border: none;
}

.button.primary:hover {
  opacity: 0.9;
}

/* Membership System Styles - adapted to existing UI */

/* Auth Components */
.auth-container {
  width: 100%;
  max-width: 400px;
  margin: 40px auto;
}

.auth-card {
  background-color: var(--input-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 2rem;
}

.auth-header {
  text-align: center;
  margin-bottom: 2rem;
}

.auth-header h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.auth-header p {
  color: var(--secondary-text);
}

.auth-form .form-group {
  margin-bottom: 1.5rem;
}

.auth-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: var(--secondary-text);
}

.auth-form input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 0.9rem;
}

.auth-form input:focus {
  outline: none;
  border-color: var(--accent-color);
}

.auth-button {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--text-color);
  color: var(--bg-color);
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;
}

.auth-button:hover {
  opacity: 0.9;
}

.auth-divider {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
  color: var(--secondary-text);
}

.auth-divider::before,
.auth-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--border-color);
}

.auth-divider span {
  padding: 0 1rem;
  font-size: 0.9rem;
}

.social-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem;
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 0.9rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: border-color 0.2s;
}

.social-button:hover {
  border-color: var(--text-color);
}

.social-button svg {
  margin-right: 0.75rem;
}

.auth-footer {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: var(--secondary-text);
}

.auth-footer a {
  color: var(--accent-color);
  text-decoration: none;
}

.auth-footer a:hover {
  text-decoration: underline;
}

.error-message {
  background-color: rgba(255, 0, 0, 0.1);
  color: #ff6b6b;
  padding: 0.75rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.success-message {
  background-color: rgba(0, 255, 0, 0.1);
  color: #4ade80;
  padding: 0.75rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

/* Admin Panel */
.admin-dashboard {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  height: calc(100vh - 70px);
}

.admin-sidebar {
  width: 200px;
  background-color: var(--input-bg);
  border-right: 1px solid var(--border-color);
  padding: 1.5rem 1rem;
}

.admin-sidebar h3 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  padding-left: 0.5rem;
}

.admin-sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-sidebar nav li {
  margin-bottom: 0.5rem;
}

.admin-sidebar nav a {
  display: block;
  padding: 0.75rem 0.5rem;
  color: var(--secondary-text);
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.admin-sidebar nav a:hover {
  background-color: var(--bg-color);
  color: var(--text-color);
}

.admin-content {
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
}

.admin-header {
  margin-bottom: 2rem;
}

.admin-header h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.admin-header p {
  color: var(--secondary-text);
  font-size: 0.9rem;
}

.admin-search {
  margin-bottom: 1.5rem;
}

.admin-search form {
  display: flex;
  gap: 0.5rem;
}

.admin-search input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 0.9rem;
}

.users-table {
  background-color: var(--input-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
}

.table-header {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  font-size: 0.9rem;
  color: var(--secondary-text);
}

.table-row {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  align-items: center;
  font-size: 0.9rem;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--accent-color);
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bg-color);
  font-weight: 500;
  font-size: 0.8rem;
}

.status-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
}

.status-badge.active {
  background-color: rgba(0, 255, 0, 0.1);
  color: #4ade80;
}

.status-badge.inactive {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--secondary-text);
}

.role-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
}

.role-badge.admin {
  background-color: rgba(0, 112, 243, 0.1);
  color: var(--accent-color);
}

.action-button {
  padding: 0.5rem 0.75rem;
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-color);
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.2s;
}

.action-button:hover {
  border-color: var(--text-color);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  gap: 1rem;
}

.page-info {
  color: var(--secondary-text);
  font-size: 0.9rem;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: var(--input-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 100%;
  max-width: 450px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.modal-header h3 {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: var(--secondary-text);
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-content {
  padding: 1.5rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem 1.5rem;
  border-top: 1px solid var(--border-color);
}

/* Responsive */
@media (max-width: 768px) {
  .admin-dashboard {
    flex-direction: column;
    height: auto;
  }
  
  .admin-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
    padding: 1rem;
  }
  
  .admin-sidebar nav ul {
    display: flex;
    flex-wrap: wrap;
  }
  
  .admin-sidebar nav li {
    margin-right: 0.5rem;
  }
  
  .subscription-plans {
    grid-template-columns: 1fr;
  }
  
  .table-header, .table-row {
    grid-template-columns: 2fr 2fr 1fr;
  }
  
  .table-header div:nth-child(n+4), 
  .table-row div:nth-child(n+4) {
    display: none;
  }
}